import BaseService from '@/services/BaseService'

class PromoDealsService extends BaseService {
  constructor() {
    super()
  }

  getPromoDeals(params = {}) {
    this.endPoint = 'api/v2/deals'
    return this.get(params).then(response => {
      return response.data
    })
  }

  setPromoDeals(data) {
    this.endPoint = 'api/v2/deals'
    return this.post(data).then(response => {
      return response.data
    })
  }

  putPromoDeals(id, data) {
    this.endPoint = 'api/v2/deals'
    return this.putOne(id, data).then(response => {
      return response.data
    })
  }

  deletePromoDeals(id) {
    this.endPoint = 'api/v2/deals'
    return this.delete(id).then(response => {
      return response.data
    })
  }
}

export default new PromoDealsService()
