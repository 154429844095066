import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import moment from 'moment'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProductService from '@/services/Products'
import CountryService from '@/services/Country'
import PromoDealsService from '@/services/PromoDeals'

@Component({})
export default class PromoDelas extends BasePage {
  constructor() {
    super()
  }

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Deals Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Product',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Start Date',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'End Date',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Arrival Info',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Promo Price',
      align: 'right',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Total Sold',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Status',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  dealsDialog: boolean = false
  isEdit: boolean = false
  dealsDataDialog: any = {
    product: {
      keyword: null,
      loading: false,
      item: [],
      selected: null
    },
    deals_name: null,
    deals_price: null,
    start_date: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    start_time: {
      open: false,
      date: null,
      tempTime: null
    },
    end_date: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    end_time: {
      open: false,
      date: null,
      tempTime: null
    },
    arrival_info: null,
    stock_zero: false,
    base_sold: null,
    sold_qty_tier: [],
    stock: 0
  }

  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  selectedDelete: any = {}
  selectedId: number = 0
  showDeleteModal: boolean = false

  filter: any = {
    item: [
      {
        name: 'Active',
        id: 'active'
      },
      {
        name: 'Scheduled',
        id: 'scheduled'
      },
      {
        name: 'Ended',
        id: 'ended'
      }
    ],
    selected: null,
    keyword: null,
    label: 'Filter Status...',
    hint: 'Apply Status Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  selectedCountry: string = ''
  searchProduct: Function

  async mounted() {
    await this.getPromoDeals()
    this.searchProduct = debounce(this.getProducts, 500)
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getPromoDeals()
  }

  @Watch('filter.selected')
  async onFilter() {
    this.getPromoDeals()
  }

  @Watch('dealsDataDialog.product.keyword')
  async onSearchProduct() {
    this.searchProduct()
  }

  async onClearSearch() {
    this.searchInput = ''
    this.getPromoDeals()
  }

  async onClearFilter() {
    this.filter.selected = null
  }

  async getPromoDeals() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
        }
      }

      if (this.filter.selected) {
        var textFilter = 'status'
        opts.params[textFilter] = this.filter.selected.id
      } else {
        var textFilter = 'status'
        delete opts.params[textFilter]
      }

      const response = await PromoDealsService.getPromoDeals(opts)
      this.tableItems = []
      forEach(response.data, deal => {
        var sold_product = 0
        var base_sold = 0
        var product_title = ''
        var arrival_info = ''
        var deal_price = 0
        var original_currency = ''
        if (!isEmpty(deal.attributes.product_deals)) {
          sold_product = deal.attributes.product_deals[0].sold_product
          base_sold = deal.attributes.product_deals[0].base_sold
          arrival_info = deal.attributes.product_deals[0].arrival_info
          deal_price = deal.attributes.product_deals[0].deal_price
          if (deal.attributes.product_deals[0].product) {
            product_title = deal.attributes.product_deals[0].product.title
            original_currency = deal.attributes.product_deals[0].product.original_currency
          }
        }
        const item = {
          ...deal.attributes,
          start_date_format: {
            dateFormatted: moment(deal.attributes.start_date).format('DD MMM YYYY'),
            date: this.toDatabaseDate(deal.attributes.start_date)
          },
          end_date_format: {
            dateFormatted: moment(deal.attributes.end_date).format('DD MMM YYYY'),
            date: this.toDatabaseDate(deal.attributes.end_date)
          },
          start_time_format: moment(deal.attributes.start_date).format('HH:mm'),
          end_time_format: moment(deal.attributes.end_date).format('HH:mm'),
          total_sold: sold_product + '<br />' + '+ ' + base_sold + ' base sold',
          product_title: product_title,
          arrival_info: arrival_info,
          deal_price: deal_price,
          original_currency: original_currency
        }
        this.tableItems.push(item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getProducts() {
    try {
      this.dealsDataDialog.product.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'include': 'country'
        }
      }

      if (!isEmpty(this.dealsDataDialog.product.keyword)) {
        var textFilter = 'filter[title][like]'
        opts.params[textFilter] = this.dealsDataDialog.product.keyword
      } else {
        var textFilter = 'filter[title][like]'
        delete opts.params[textFilter]
      }

      const response = await ProductService.getProductsLists(opts)
      this.dealsDataDialog.product.items = []
      forEach(response.data, product => {
        const item = {
          ...product.attributes,
          country: response.included.country[product.attributes.country_id].attributes.name
        }
        this.dealsDataDialog.product.items.push(item)
      })
      if (this.dealsDataDialog.product.selected) {
        this.dealsDataDialog.product.items.push(this.dealsDataDialog.product.selected)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.dealsDataDialog.product.loading = false
    }
  }

  openCreateDialog() {
    this.isEdit = false
    this.dealsDataDialog = {
      product: {
        keyword: null,
        loading: false,
        item: [],
        selected: null
      },
      deals_name: null,
      deals_price: null,
      start_date: {
        open: false,
        date: null,
        dateFormatted: null,
        tempDate: null
      },
      start_time: {
        open: false,
        time: null,
        tempTime: null
      },
      end_date: {
        open: false,
        date: null,
        dateFormatted: null,
        tempDate: null
      },
      end_time: {
        open: false,
        time: null,
        tempTime: null
      },
      arrival_info: null,
      stock_zero: false,
      base_sold: null,
      sold_qty_tier: []
    }
    this.getProducts()
    this.addNewPrice()
    this.dealsDialog = true
  }

  closeDialog() {
    this.dealsDialog = false
  }

  addNewPrice() {
    const deals_price = {
      id: null,
      min_sold: null,
      deal_price: null
    }
    this.dealsDataDialog.sold_qty_tier.push(deals_price)
  }

  onPriceDeleted(index, id) {
    this.dealsDataDialog.sold_qty_tier.splice(index, 1)
  }

  onStartDateInput(event) {
    this.dealsDataDialog.start_date.tempDate = event
  }
  onStartDateCancel() {
    this.dealsDataDialog.start_date.open = false
  }
  onStartDateDone() {
    this.dealsDataDialog.start_date.date = this.dealsDataDialog.start_date.tempDate
    this.dealsDataDialog.start_date.dateFormatted = moment(this.dealsDataDialog.start_date.tempDate).format('DD MMMM YYYY')
    this.dealsDataDialog.start_date.open = false
  }

  onEndDateInput(event) {
    this.dealsDataDialog.end_date.tempDate = event
  }
  onEndDateCancel() {
    this.dealsDataDialog.end_date.open = false
  }
  onEndDateDone() {
    this.dealsDataDialog.end_date.date = this.dealsDataDialog.end_date.tempDate
    this.dealsDataDialog.end_date.dateFormatted = moment(this.dealsDataDialog.end_date.tempDate).format('DD MMMM YYYY')
    this.dealsDataDialog.end_date.open = false
  }

  onStartTimeInput(event) {
    this.dealsDataDialog.start_time.tempTime = event
  }
  onStartTimeCancel() {
    this.dealsDataDialog.start_time.open = false
  }
  onStartTimeDone() {
    this.dealsDataDialog.start_time.time = this.dealsDataDialog.start_time.tempTime
    this.dealsDataDialog.start_time.open = false
  }

  onEndTimeInput(event) {
    this.dealsDataDialog.end_time.tempTime = event
  }
  onEndTimeCancel() {
    this.dealsDataDialog.end_time.open = false
  }
  onEndTimeDone() {
    this.dealsDataDialog.end_time.time = this.dealsDataDialog.end_time.tempTime
    this.dealsDataDialog.end_time.open = false
  }

  async addDeals() {
    try {
      const validationResponse = await this.$validator.validateAll('dealsDialogScope')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        this.dealsDialog = false
        var deals_price = []

        if (this.dealsDataDialog.base_sold) {
          deals_price.push({
            min_sold: 0,
            price: this.dealsDataDialog.deals_price,
            base_sold: this.dealsDataDialog.base_sold
          })
          forEach(this.dealsDataDialog.sold_qty_tier, deal => {
            deals_price.push({
              min_sold: deal.min_sold,
              price: deal.deal_price,
              base_sold: this.dealsDataDialog.base_sold
            })
          })
        } else {
          deals_price.push({
            min_sold: 0,
            price: this.dealsDataDialog.deals_price
          })
          forEach(this.dealsDataDialog.sold_qty_tier, deal => {
            deals_price.push({
              min_sold: deal.min_sold,
              price: deal.deal_price
            })
          })
        }

        const payload = {
          name: this.dealsDataDialog.deals_name,
          start_date: moment(this.dealsDataDialog.start_date.dateFormatted + ' ' + this.dealsDataDialog.start_time.time).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(this.dealsDataDialog.end_date.dateFormatted + ' ' + this.dealsDataDialog.end_time.time).format('YYYY-MM-DD HH:mm:ss'),
          products: [
            {
              id: this.dealsDataDialog.product.selected.id,
              arrival_info: this.dealsDataDialog.arrival_info,
              set_to_zero: this.dealsDataDialog.stock_zero,
              deals_price: deals_price,
              stock: this.dealsDataDialog.stock
            }
          ]
        }

        PromoDealsService.setPromoDeals(payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getPromoDeals()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 1500
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async openEditDialog(props) {
    this.isEdit = true
    this.selectedId = props.item.id
    const sold_qty_tier = [...props.item.product_deals]
    sold_qty_tier.splice(0, 1)
    const product = {
      ...props.item.product_deals[0].product,
      country: await this.getCountryById(props.item.product_deals[0].product.country_id)
    }
    this.dealsDataDialog = {
      product: {
        keyword: null,
        loading: false,
        item: [],
        selected: product
      },
      deals_name: props.item.name,
      deal_price_id: props.item.product_deals[0].id,
      deals_price: props.item.product_deals[0].deal_price,
      start_date: {
        open: false,
        date: props.item.start_date_format.date,
        dateFormatted: props.item.start_date_format.dateFormatted,
        tempDate: null
      },
      start_time: {
        open: false,
        time: props.item.start_time_format,
        tempTime: null
      },
      end_date: {
        open: false,
        date: props.item.end_date_format.date,
        dateFormatted: props.item.end_date_format.dateFormatted,
        tempDate: null
      },
      end_time: {
        open: false,
        time: props.item.end_time_format,
        tempTime: null
      },
      arrival_info: props.item.product_deals[0].arrival_info,
      stock_zero: props.item.product_deals[0].set_to_zero === 1 ? true : false,
      base_sold: props.item.product_deals[0].base_sold,
      sold_qty_tier: sold_qty_tier,
      stock: props.item.product_deals[0].stock
    }
    this.getProducts()
    this.dealsDialog = true
  }

  async getCountryById(id) {
    const response = await CountryService.getCountryById(id)
    return response.data.data.attributes.name
  }

  async updateDeals() {
    try {
      const validationResponse = await this.$validator.validateAll('dealsDialogScope')
      if (validationResponse) {
        this.dealsDialog = false
        this.showLoading({ text: 'Saving...' })
        var deals_price = []

        if (this.dealsDataDialog.base_sold) {
          deals_price.push({
            id: this.dealsDataDialog.deal_price_id,
            min_sold: 0,
            price: this.dealsDataDialog.deals_price,
            base_sold: this.dealsDataDialog.base_sold
          })
          forEach(this.dealsDataDialog.sold_qty_tier, deal => {
            const item: any = {
              min_sold: deal.min_sold,
              price: deal.deal_price,
              base_sold: this.dealsDataDialog.base_sold
            }
            if (deal.id) {
              item.id = deal.id
            }
            deals_price.push(item)
          })
        } else {
          deals_price.push({
            id: this.dealsDataDialog.deal_price_id,
            min_sold: 0,
            price: this.dealsDataDialog.deals_price
          })
          forEach(this.dealsDataDialog.sold_qty_tier, deal => {
            const item: any = {
              min_sold: deal.min_sold,
              price: deal.deal_price
            }
            if (deal.id) {
              item.id = deal.id
            }
            deals_price.push(item)
          })
        }

        const payload = {
          name: this.dealsDataDialog.deals_name,
          start_date: moment(this.dealsDataDialog.start_date.dateFormatted + ' ' + this.dealsDataDialog.start_time.time).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(this.dealsDataDialog.end_date.dateFormatted + ' ' + this.dealsDataDialog.end_time.time).format('YYYY-MM-DD HH:mm:ss'),
          products: [
            {
              id: this.dealsDataDialog.product.selected.id,
              arrival_info: this.dealsDataDialog.arrival_info,
              set_to_zero: this.dealsDataDialog.stock_zero,
              deals_price: deals_price,
              stock: this.dealsDataDialog.stock
            }
          ]
        }

        PromoDealsService.putPromoDeals(this.selectedId, payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getPromoDeals()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 1500
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item.id
    this.showDeleteModal = true
  }

  async deleteDeals() {
    try {
      this.showDeleteModal = false
      this.showLoading({ text: 'Saving...' })
      PromoDealsService.deletePromoDeals(this.selectedDelete).then(response => {
        this.showSnackbar({
          text: 'Success',
          color: 'green',
          timeout: 1500
        })
        this.getPromoDeals()
      }).catch(error => {
        this.showSnackbar({
          text: error.response.data.message,
          color: 'red',
          timeout: 1500
        })
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // async updateProvince() {
  //   try {
  //     this.showLoading({ text: 'Saving...' })
  //     this.provinceDialog = false
  //     const payload = {
  //       name: this.provinceDataDialog.name,
  //       country_id: this.provinceDataDialog.country_id
  //     }
  //     await ProvinceService.updateProvince(this.selectedIdProvince, payload);
  //     this.showSnackbar({
  //       text: 'Saved Successfully!',
  //       color: 'green',
  //       timeout: 1500
  //     })
  //     this.getProvinces()
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.closeLoading()
  //   }
  // }
}
