import BaseService from '@/services/BaseService'

class CountryService extends BaseService {
  constructor() {
    super()
  }

  async getCountries(opts = {}) {
    this.endPoint = 'api/v2/country'
    return this.get(opts)
  }

  async getCountryById(id = 0) {
    this.endPoint = `api/v2/country/${id}`
    return this.get(id)
  }

  async getOneCountry(id, opts = {}) {
    this.endPoint = 'api/v2/country'
    return this.getOne(id, opts)
  }

  async updateCountry(id, data = {}) {
    this.endPoint = 'api/v2/country'
    return this.putOne(id, data)
  }
}

export default new CountryService()
